/*.bg-image {*/
/*    background-attachment: fixed;*/
/*    background-position: center;*/
/*    background-repeat: no-repeat;*/
/*    background-size: cover;*/
/*    background-image: url("../assets/imgs/manoscritto-Baruffaldi.jpg");*/
/*    position: fixed;*/
/*    top: 0;*/
/*    bottom: 0;*/
/*    left: 0;*/
/*    right: 0;*/
/*    z-index: -222;*/
/*}*/
.fixed-header{
    position: fixed;
    left: 0px;
    right: 0px;
    z-index: 10;
}
