strike {
    text-decoration: line-through;
}

.footnotes {
    padding-top: 8px;
    margin-top: 10px;
    border-top: 1px solid silver;
    font-size: 1rem;
}
.footnote{
    -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
    page-break-inside: avoid; /* Firefox */
    break-inside: avoid; /* IE 10+ */


}
#pageBody{
    counter-reset: footnotes;
}
#pageBody p{
    margin: 0px;
    font-size: 1.5rem;
}
mark {
    background-color: transparent;
}

mark[textannotation] {
    counter-increment: footnotes; /* 1 */
    text-decoration: none; /* 2 */
    color: inherit; /* 2 */
    cursor: default; /* 2 */
    outline: none; /* 2 */
    background-color: transparent;
}
mark.notes[textannotation]::after {
    content: '[' counter(footnotes) ']'; /* 1 */
    vertical-align: super; /* 2 */
    font-size: 0.5em; /* 3 */
    margin-left: 2px; /* 4 */
    color: #000000; /* 5 */
    text-decoration: underline; /* 5 */
    cursor: pointer; /* 5 */
}

hilited{
    background-color: #e3bc00;
}

.divImage {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
}
